import { Store } from "react-notifications-component";

/* 
  BASE NOTIFICATION CONFIG
*/
const notification = {
  title: "Title",
  message: "Message",
  type: "default",
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__bounceIn"],
  animationOut: ["animate__animated", "animate__zoomOut"],
  dismiss: {
    duration: 8000,
    onScreen: false,
    showIcon: true,
  },
};

/* 
  HELPER FUNCTIONS
*/
function notify(type, title, message) {
  const messageArr = prepareMsgArr(message);
  messageArr.forEach((msg) => {
    Store.addNotification({
      ...notification,
      title: title || "-",
      message: msg || "-",
      type: type,
    });
  });
}

function prepareMsgArr(message) {
  if (message.constructor !== Array && typeof message !== "string") {
    return [];
  }
  return message.constructor === Array ? message : [message];
}

/* 
  NOTIFICATION TYPES
*/
export function success(message, title) {
  notify(
    "success",
    title || "Sucesso",
    message || "Operação efetuada com sucesso"
  );
}

export function error(message, title) {
  notify(
    "danger",
    title || "Erro",
    message || "Ocorreu um erro ao efetuar a operação"
  );
}

export function warning(message, title) {
  notify("warning", title || "Aviso", message);
}

export function info(message, title) {
  notify("default", title || "-", message || "-");
}
