import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";
import FormularioPage from "./pages/FormularioPage";
import "react-notifications-component/dist/theme.css";
import { ReactNotifications } from "react-notifications-component";

function App() {
  return (
    <>
      <ReactNotifications />
      <FormularioPage />
    </>
  );
}

export default App;
