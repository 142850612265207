import React from "react";

const FileInput = ({ name, val, placeholder, fn }) => {
  return (
    <div className="input-group input-group-sm mb-3">
      <label htmlFor={name} className="btn btn-sm btn-secondary">
        Anexar Pdf
      </label>
      <input
        type={"text"}
        placeholder={placeholder}
        className="form-control"
        value={val || ""}
        readOnly
      />
      <button
        type="button"
        onClick={() => fn(name)}
        disabled={!val}
        className="btn-group btn btn-sm btn-secondary">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-x"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <line x1="18" y1="6" x2="6" y2="18" />
          <line x1="6" y1="6" x2="18" y2="18" />
        </svg>
      </button>
    </div>
  );
};

export default FileInput;
