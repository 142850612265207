import React from "react";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Container,
  Form,
  Input,
  FormGroup,
  Label,
  Button,
  Alert,
  Spinner,
} from "reactstrap";
import documents from "../documents";
import FileInput from "./FileInput";

class DocumentsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      url: process.env.REACT_APP_TITLE,
      logo: process.env.REACT_APP_LOGO,
      errors: "",
      deliveryPoint: true,
      submited: false,
      loading: false,
    };
  }

  async submit(data) {
    this.setState({ loading: true });
    this.setState({ errors: "" });

    try {
      const response = await fetch(process.env.REACT_APP_API, {
        method: "POST",
        body: JSON.stringify(data),
      });

      const json = await response.json();

      if (json.status === "success") {
        //Notify.success("Dados enviados com sucesso!");

        this.setState({ submited: true });
      } else {
        //Notify.error("Campos marcados com * são obrigatórios!");

        this.setState({ errors: json.errors });
      }

      this.setState({ loading: false });
    } catch (err) {
      throw err;
    }
  }

  onInputChange = (event) => {
    if (event.target.type === "checkbox") {
      this.setState({ [event.target.name]: !event.target.checked });
    } else if (event.target.type !== "file") {
      this.setState({
        [event.target.name]: event.target.value,
      });
    } else {
      const filename = event.target.files[0].name;
      const filenameState = "file_" + event.target.name;

      if (event.target.files[0].size > 1048576) {
        Notify.error("O tamanho do arquivo é maior que 1MB");
        return;
      }

      const ext = filename.match(/\.([^\.]+)$/)[1];

      if (ext === "pdf") {
        this.toBase64(event.target.files[0]).then((b64) => {
          this.setState({ [event.target.name]: b64 });
          this.setState({ [filenameState]: filename });
        });
      } else {
        Notify.error("Ficheiro inválido!. Ficheiro deve ser do tipo PDF");
      }
    }
  };

  clearInputFile = (name) => {
    console.log(name);
    const filenameState = "file_" + name;
    this.setState({ [name]: '' });
    this.setState({ [filenameState]: '' });
  };

  toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  render() {
    return (
      <Form>
        {documents && (
          <div className="mb-5">
            <p>
              <b>{documents.texto}</b>
            </p>
          </div>
        )}

        {documents &&
          documents.perguntas.map((pergunta, idx) => (
            <FormGroup row className="form-group" key={idx}>
              <Label sm={4} className="text-start text-md-end">
                {pergunta.questao}

                {pergunta.required && !this.state[pergunta.name] && (
                  <small className="form-required">*</small>
                )}
              </Label>
              <Col sm={8}>
                <Input
                  id={pergunta.name}
                  hidden={pergunta.tipo === "file"}
                  type={pergunta.tipo}
                  name={pergunta.name}
                  required={pergunta.required}
                  value={
                    pergunta.tipo === "file" ? "" : this.state[pergunta.name]
                  }
                  onChange={this.onInputChange}
                />

                {pergunta.tipo === "file" && (
                  <FileInput
                    name={pergunta.name}
                    val={this.state[pergunta.tipo + "_" + pergunta.name]}
                    fn={this.clearInputFile}
                  />
                )}
              </Col>
            </FormGroup>
          ))}

        <Button color="dark" type="button" className="float-end m-2">Enviar</Button>
      </Form>
    );
  }
}

export default DocumentsForm;
