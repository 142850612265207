import React from "react";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Container,
  Form,
  Input,
  FormGroup,
  Label,
  Button,
  Alert,
  Spinner
} from "reactstrap";
import classnames from "classnames";

import errors from "../errors";
import caes from "../caes";
import * as Notify from "../utils/Notify";
import { PDFDocument } from "pdf-lib";
import FileInput from "../components/FileInput";
import DocumentsForm from "../components/DocumentosForm";
import FileSaver from 'file-saver';

class FormularioPage extends React.Component {

  constructor(props) {
    super(props);

    const file = require(`../questions_${process.env.REACT_APP_TITLE}.json`);

    //console.log(file);

    const namesFormMapped = file.tabs.map((t) =>
      t.seccoes.map((s) => s.perguntas.map((p) => p.name))
    );
    const namesForm = namesFormMapped
      .flat(2)
      .reduce((a, v) => ({ ...a, [v]: "" }), {});

    this.state = {
      ...namesForm,
      loading: false,
      activeTab: "1",
      showPergunta: true,
      isTab1Filled: false,
      isTab2Filled: false,
      url: process.env.REACT_APP_TITLE,
      logo: process.env.REACT_APP_LOGO,
      errors: "",
      deliveryPoint: true,
      submited: false,
      loading: false,
      questions: file,
      cae: 35113,
    };
    
  }

  async submit(data) {

    this.setState({ loading: true });
    this.setState({ errors: '' });

    try {
      const response = await fetch(process.env.REACT_APP_API, {
        method: "POST",
        body: JSON.stringify(data),
      });

      const json = await response.json();

      if (json.status === "success") {
        
        this.setState({ submited: true });

      } else {
        this.setState({ errors: json.errors });
      }

      this.setState({ loading: false });

    } catch (err) {

      this.setState({ loading: false });

      throw err;
    }
  }

  //load dos dados guardados do formulário
  componentDidMount() {
      
      const contact_form_storage = JSON.parse(localStorage.getItem('contact_form_'+process.env.REACT_APP_TITLE));

      if (contact_form_storage) {
        //console.log(contact_form_storage);
        //this.setState(contact_form_storage);

        //this.setState({ nomeCliente : 'teste'})

        for (const key in this.state) {
         // console.log(key);
          console.log(contact_form_storage[key] );

         // this.setState({ key: contact_form_storage[key] });

          

        }
      }
  }

  //guarda alterações no formulário
  componentWillUpdate(nextProps, nextState) {

      const objCopy = {...nextState};

      //não adicionar ficheiros no localstorage ou dá merda depois..
      delete objCopy.file_contratoExcedentes;
      delete objCopy.contratoExcedentes;
      delete objCopy.compMCP
      delete objCopy.comprovativoIban
      delete objCopy.procuracaoDeclaracao
      delete objCopy.rgpd
      delete objCopy.file_compMCP
      delete objCopy.file_comprovativoIban
      delete objCopy.file_procuracaoDeclaracao
      delete objCopy.file_rgpd
      delete objCopy.questions

      localStorage.setItem('contact_form_'+process.env.REACT_APP_TITLE, JSON.stringify(objCopy));
  }

  setActiveTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  onInputChange = (event) => {

    if (event.target.type === "checkbox") {

      this.setState({ [event.target.name]: !event.target.checked });

    } else if (event.target.type !== "file") {

      this.setState({
        [event.target.name]: event.target.value,
      });

      if (event.target.type === 'number' && event.target.value < event.target.min) {
        console.log('invalid');
        this.setState({
          [event.target.name]: event.target.min,
        });
      }

    } else {

      const filename = event.target.files[0].name;
      const filenameState = "file_" + event.target.name;

      if (event.target.files[0].size > process.env.REACT_APP_FILE_LIMIT) {
        Notify.error(`O tamanho do arquivo é maior que ${ (process.env.REACT_APP_FILE_LIMIT / (1024*1024)).toFixed(0) } MB`);
        return;
      }

      const ext = filename.match(/\.([^\.]+)$/)[1];

      if (ext === "pdf" || ext === 'PDF') {
        this.toBase64(event.target.files[0]).then((b64) => {
          this.setState({ [event.target.name]: b64 });
          this.setState({ [filenameState]: filename });
        });
      } else {
        Notify.error("Ficheiro inválido!. Ficheiro deve ser do tipo PDF");
      }
    }

  };

  onDismissErrors = () => {
    this.setState({ errors: '' });
  }

  onDismissSubmited = () => {
    this.setState({ submited: '' });
  }

  clearInputFile = (name) => {

    const filenameState = "file_" + name;

    this.setState({ [name]: "" });
    this.setState({ [filenameState]: "" });
  };

  toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  goToTab2 = () => {
    const namesFormMapped = this.state.questions.tabs.map((t) =>
      t.seccoes.map((s) => s.perguntas.map((p) => p.name))
    );

    const namesFormTab1 = namesFormMapped[0].flat();

    const isFilled = namesFormTab1
      .map((n, i) => this.state[n] !== "")
      .every((elem) => elem === true);

    /*  if (!isFilled) {
      Notify.error("Tem que preencher todos os campos");
    } else { */
    this.setState({ isTab1Filled: true });
    this.setActiveTab("2");
    //}
  };

  fillPDF = async (id) => {

    const monthNames = [
      "janeiro",
      "fevereiro",
      "março",
      "abril",
      "maio",
      "junho",
      "julho",
      "agosto",
      "setembro",
      "outubro",
      "novembro",
      "dezembro",
    ];

    const current = new Date();
    const cDay = String(current.getDate()).padStart(2, "0");
    const cMonth = monthNames[current.getMonth()];
    const cYear = current.getUTCFullYear();

    // Contrato Compra e Venda
    const formPdfBytesContratoCV = await fetch(require(`../documents/${process.env.REACT_APP_TITLE}/contrato.pdf`)).then((r) => r.arrayBuffer());
    const pdfDocContratoCV = await PDFDocument.load(formPdfBytesContratoCV);
    const formContratoCV = pdfDocContratoCV.getForm();

    // Procuracao Declaracao
    const formPdfBytesProcDec = await fetch(require(`../documents/${process.env.REACT_APP_TITLE}/procuracao.pdf`)).then((r) => r.arrayBuffer());
    const pdfDocProcDec = await PDFDocument.load(formPdfBytesProcDec);
    const formProcDec = pdfDocProcDec.getForm();

    // RGPD
    const formPdfBytesRGPD = await fetch(require(`../documents/${process.env.REACT_APP_TITLE}/rgpd.pdf`)).then((r) => r.arrayBuffer());
    const pdfDocRGPD = await PDFDocument.load(formPdfBytesRGPD);
    const formRGPD = pdfDocRGPD.getForm();

    const namesFormMapped = this.state.questions.tabs.map((t) =>
      t.seccoes.map((s) =>
        s.perguntas.map((p) => ({ name: p.name, value: this.state[p.name] }))
      )
    );

    const namesFormTab1 = namesFormMapped[0].flat();

    namesFormTab1.forEach((o) => {

      if (formContratoCV.getFieldMaybe(o.name)) {
        formContratoCV.getTextField(o.name).setText(o.value);
      }

      if (formProcDec.getFieldMaybe(o.name)) {
        formProcDec.getTextField(o.name).setText(o.value);
      }
    });


    const morada =
      this.state["morada"] +
      ", " +
      this.state["codPostal"] +
      " " +
      this.state["localidade"];


    if (id === 1) {

      formContratoCV.getTextField("moradaCliente").setText(morada);
      formContratoCV.getTextField("moradaProd").setText(morada);
  
      formContratoCV.getTextField("assinaturaDia").setText(cDay);
      formContratoCV.getTextField("assinaturaMes").setText(cMonth);

      //preenche a checkbox das tarifas no pdf
      if (this.state["tarifa"]){
        const checkBox = formContratoCV.getCheckBox(this.state["tarifa"]);
        checkBox.check()
      }

      // Contrato Compra e Venda
      const pdfBytesContratoCV = await pdfDocContratoCV.save({useObjectStreams:false});
      const blobContratoCV = new Blob([pdfBytesContratoCV], {
        type: "application/pdf",
      });

      FileSaver.saveAs(blobContratoCV, "contrato.pdf");

    } else if (id===2) {

      formProcDec.getTextField("moradaCliente").setText(morada);
      formProcDec.getTextField("dias").setText(cDay);
      formProcDec.getTextField("mes").setText(cMonth);

      // Procuracao Declaracao
      const pdfBytesProcDec = await pdfDocProcDec.save({useObjectStreams:false});
      const blobProcDec = new Blob([pdfBytesProcDec], {
        type: "application/pdf",
      });

      FileSaver.saveAs(blobProcDec, "procuracao.pdf");

    } else {

      formRGPD.getTextField("assinaturaDia").setText(cDay);
      formRGPD.getTextField("assinaturaMes").setText(cMonth);
      formRGPD.getTextField("assinaturaAno").setText(cYear.toString());

      // RGPD
      const formPdfBytesRGPD = await pdfDocRGPD.save({useObjectStreams:false});
      const blobProcRGPD = new Blob([formPdfBytesRGPD],{
        type: "application/pdf"
      });

      FileSaver.saveAs(blobProcRGPD, "rgpd.pdf");

    }

  };

  sendData = () => {
    //if (this.state.compMCP === "" || this.state.procuracaoDeclaracao === "") {
    // Notify.error("Tem que preencher todos os campos");
    //} else {
    this.submit(this.state);
    // }
  };

  render() {
    return (
      <>
      <Container className="mt-2 mb-2" fluid="md">
      <Row>
          <Col
            
            lg={{
              offset: 1,
              size: 8,
            }}>
            <Row className="mb-5 text-start">
              <Col xs={{size:4, offset:0}}>
                <a
                  href={ (process.env.REACT_APP_TITLE === 'ezu') ? 'https://ezu.pt' : 'https://coopernico.org'}
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    src={process.env.REACT_APP_LOGO}
                    alt={process.env.REACT_APP_TITLE}
                  />
                </a>
              </Col>
              <Col md="8"  xs={{size:4, offset:0}} className="text-md-end align-items-center align-self-center">
                <h3 className={ (process.env.REACT_APP_TITLE === 'ezu') ? 'ezu' : 'coopernico' }>GGS - Registo de Unidade Física</h3>
              </Col>
            </Row>
          </Col>
          </Row>
</Container>
      <Container className="mt-2 mb-2" fluid="md">
        <Row>

          <Col
            lg={{
              offset: 1,
              size: 8,
            }}>
            <Nav tabs justified className="mb-3">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "1",
                  })}
                  onClick={() => this.setActiveTab("1")}>
                  Preenchimento de Dados
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "2",
                  })}
                  onClick={() => this.setActiveTab("2")}
                  disabled>
                  Documentos
                </NavLink>
              </NavItem>
              {/*<NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "3",
                  })}
                  onClick={() => this.setActiveTab("3")}
                  >
                  Enviar só documentos
                </NavLink>
                </NavItem>*/}
            </Nav>

            {this.state.errors && 
            <Alert color="warning" isOpen={true} toggle={this.onDismissErrors}>
                <h5 className="alert-heading">Erro ao submeter o formulário!</h5>
                <p>{this.state.errors.length} campos não estão preechidos.</p>
                <p>Campos marcados com * são obrigatórios!</p>

                {
                  this.state.errors.map(error => (
                      errors.errors.map((value, i) => {
                        return (
                         
                          <div key={i}><small>{value.name === error && value.questao}</small></div>
                        
                        )
                      })
                   ))
                }
               
            </Alert>
            }
            

            {this.state.loading && <div className="loadingOverlay"><Spinner color="primary" /></div>}

            {this.state.submited  ? ( <Alert color="success" isOpen={true} toggle={this.onDismissSubmited}>
                <h5 className="alert-heading">Dados enviados com sucesso!</h5>
              </Alert>): (

            <TabContent activeTab={this.state.activeTab}>
              
              {this.state.questions.tabs.map((tab, t) => (
                <TabPane tabId={tab.id} key={"tab_" + t}>
                  <Row>
                    <Col sm="12">
                      
                      {this.state.activeTab === "2" && (
                        <>
                          <Row className="m-2">
                            <Col>Contrato de Excedentes</Col>
                            <Col>
                              <Button
                                color="dark"
                                className="float-end"
                                onClick={() => this.fillPDF(1)}>
                                Gerar
                              </Button>
                            </Col>
                          </Row>

    
                          <Row className="m-2">
                            <Col>RGPD</Col>
                            <Col>
                              <Button
                                color="dark"
                                className="float-end"
                                onClick={() => this.fillPDF(3)}>
                                Gerar
                              </Button>
                            </Col>
                          </Row>
                          
                          { 
                        //se a pInstaladaKW é inferior a X valor não é necessário procuração declaração
                        //alterar valor em .env REACT_APP_LIMIT_PINSTALADAKW
                      (parseInt(this.state.pInstaladaKW) > parseInt(process.env.REACT_APP_LIMIT_PINSTALADAKW)) && 
                          <Row className="m-2">
                            <Col>Procuração e Declaração</Col>
                            <Col>
                              <Button
                                color="dark"
                                className="float-end"
                                onClick={() => this.fillPDF(2)}>
                                Gerar
                              </Button>
                            </Col>
                            </Row>
                          }
                          
                        </>
                      )}


                      <Form>
                        {tab.seccoes.map((seccao, i) => (
                          <div key={"seccao_" + i}>
                            <h6 className="text-start mb-5 mt-5">
                              {seccao.nome}
                            </h6>

                            {(seccao.procuracao && seccao.noprocuracao) && (
                              <>
                              <div dangerouslySetInnerHTML={{__html: parseInt(this.state.pInstaladaKW) > parseInt(process.env.REACT_APP_LIMIT_PINSTALADAKW) ? seccao.procuracao : seccao.noprocuracao}} />

<p>Só são permitidos ficheiros <b>pdf</b> com tamanho máximo de <b> {(process.env.REACT_APP_FILE_LIMIT / (1024*1024)).toFixed(0) } MB</b></p><p className="mt-3 mb-3">Para comprimir ficheiros pdf, pode usar o seguinte <a target='_blank' href="https://www.pdf2go.com/compress-pdf">serviço</a></p>

<hr />
                            </>
                            )}

                            {seccao.perguntas.map(
                              (pergunta, j) =>

                                !pergunta.hidden && 

                                (!pergunta.parentName || pergunta.parentValues.includes(this.state[pergunta.parentName])) && (
                                  

                                  <FormGroup className="form-group mb-1" key={"pergunta_" + j} row>
                                      
                                    <Label
                                      sm={4}
                                      className="text-start text-md-end">
                                        
                                      {pergunta.tipo !== 'checkbox' &&  pergunta.questao}

                                      {(pergunta.name === 'procuracaoDeclaracao' && parseInt(this.state.pInstaladaKW) < parseInt(process.env.REACT_APP_LIMIT_PINSTALADAKW)) ||
                                      (pergunta.required && !this.state[pergunta.name]) &&  (
                                        <small className="form-required">
                                          * 
                                        </small>
                                      )}
                                     
                     

                                    </Label>                                 

                                    <Col sm={8}>
                                      <Input
                                        id={pergunta.name}
                                        hidden={pergunta.tipo === "file"}
                                        type={pergunta.tipo}
                                        name={pergunta.name}
                                        min={pergunta.min}
                                        maxLength={pergunta.limite}
                                        required={pergunta.required}
                                        value={
                                          pergunta.tipo === "file"
                                            ? ""
                                            : this.state[pergunta.name]
                                        }
                                        placeholder={pergunta.placeholder}
                                        onChange={this.onInputChange}>
                                        {pergunta.tipo === "select" ? (
                                          <>
                                            <option value="" disabled>
                                              --Escolher--
                                            </option>
                                            {pergunta.name === "cae"
                                              ? caes.map((cae, c) => (
                                                  <option
                                                    key={"cae_" + c}
                                                    value={cae.codigo}>
                                                    {cae.codigo}
                                                    {" - "}
                                                    {cae.descricao}
                                                  </option>
                                                ))
                                              : pergunta.options.map(
                                                  (option, o) => (
                                                    <option
                                                      key={"opt_" + o}
                                                      value={option.codigo}>
                                                      {option.nome}
                                                    </option>
                                                  )
                                                )}
                                              
                                          </>
                                        ) : null}
                                      </Input>
                                 

                                      {pergunta.tipo === "file" && (
                                        
                                        <FileInput
                                          name={pergunta.name}
                                          placeholder={pergunta.placeholder}
                                          val={
                                            this.state[
                                              pergunta.tipo +
                                                "_" +
                                                pergunta.name
                                            ]
                                          }
                                          fn={this.clearInputFile}
                                        />

                                      )}

                                    {pergunta.tipo === 'checkbox' &&  <span className="px-2"><small>{pergunta.questao}</small></span>}


                                    <p><small className="input-help">{pergunta.help}</small></p>

                                    </Col>

                                   
                                   
                                  </FormGroup>
                                )
                            )}
                          </div>
                        ))}
                        {tab.id === "1" ? (
                          <Button
                            color="dark"
                            className="float-end m-2"
                            onClick={this.goToTab2}>
                            Próximo
                          </Button>
                        ) : (
                          <Button
                            color="dark"
                            className="float-end m-2"
                            onClick={this.sendData}
                            disabled={this.state.loading}
                            >
                            Enviar
                          </Button>
                        )}
                      </Form>
                      
                    </Col>

                  </Row>

                  
                </TabPane>
                
              ))}

              
               <TabPane tabId={'3'}>

               <h6 className="text-start text-uppercase mb-5 mt-5">Submeter documentos</h6>

              

              <DocumentsForm />

               </TabPane>
            </TabContent>
            )}
          </Col>
        </Row>
      </Container>
      </>
    );
  }
}

export default FormularioPage;
